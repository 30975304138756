











import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'community-item': () => import('@/modules/community/home/components/community-item.vue'),
  },
})
export default class CommunityList extends Vue {
  @Prop() communities!: any[]
  @Prop({ default: false, type: Boolean }) fixedColumn!: boolean
}
